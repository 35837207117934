import { Listbox } from "@headlessui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiX } from "react-icons/fi";
import { Popup, useMap } from "react-leaflet";
import { Dropdown } from "../../components";
import { DropdownItem } from "../../components/Dropdown/Dropdown";
import { UserMarker, UserPolygon, UserPolyline } from "../../types/userTypes";

type NotePopupProps = {
	feature: UserMarker | UserPolyline | UserPolygon;
	onDelete: () => void;
	onChangeNote: (note: string) => void;
	onChangeOther?: (head: "arrow" | "simple", lineGap: number) => void;
	onChangeDelay?: number;
	type: "marker" | "polyline" | "polygon";
};

const NotePopup: React.FC<NotePopupProps> = (props) => {
	const { feature, type, onDelete, onChangeNote, onChangeOther, onChangeDelay = 500 } = props;
	const [notes, setNote] = useState(feature.notes);
	const [head, setHead] = useState(JSON.parse(feature.geometry).head || null);
	const [lineGap, setLineGap] = useState(JSON.parse(feature.geometry).lineGap || null);
	const geometry = useMemo(() => JSON.parse(feature.geometry), [feature.geometry]);

	const map = useMap();
	const { t } = useTranslation();

	const polylineHeadTypes: DropdownItem[] = [
		{ name: "Arrow", value: "arrow" },
		{ name: "Simple", value: "simple" },
	];

	useEffect(() => {
		if (feature.notes !== notes) {
			let timer = setTimeout(() => {
				onChangeNote(notes);
			}, onChangeDelay);
			return () => clearTimeout(timer);
		}
	}, [notes]);

	useEffect(() => {
		if ((type === "polyline" && head !== geometry.head) || lineGap !== geometry.lineGap) {
			onChangeOther && onChangeOther(head, lineGap);
		}
	}, [head, lineGap]);

	const closePopup = () => {
		map.closePopup();
	};

	const title = useMemo(() => {
		if (type === "marker") {
			return t("userFeatures.markerTitle");
		}

		if (type === "polyline") {
			return t("userFeatures.lineTitle");
		}

		if (type === "polygon") {
			return t("userFeatures.polygonTitle");
		}
	}, [type, t]);

	return (
		<Popup className='note-popup' closeButton={false}>
			<div className='note-header'>
				<h3 className='note-title'>{title}</h3>
				<div className='note-close' onClick={closePopup}>
					<FiX />
				</div>
			</div>
			<textarea
				className='note-content'
				cols={30}
				rows={5}
				value={notes}
				onChange={(e) => setNote(e.target.value)}
			/>

			{type === "polyline" && (
				<div className='flex mb-3 space-x-2'>
					<div className='flex flex-col flex-1'>
						<span>{t("userFeatures.lineHead")}</span>
						<Dropdown
							renderButton={(selected) => (
								<Listbox.Button className='bg-white/10 w-full rounded-sm px-2 py-1'>
									{selected.name}
								</Listbox.Button>
							)}
							items={polylineHeadTypes}
							defaultValue={polylineHeadTypes.find((i) => i.value === geometry.head)}
							onChange={(v) => setHead(v.value)}
						/>
					</div>
					<div className='flex flex-col flex-1'>
						<span>{t("userFeatures.lineGap")}</span>
						<input
							type='number'
							className='w-full bg-white/10 rounded-sm outline-none px-2 py-1'
							value={lineGap}
							onChange={(e) => setLineGap(e.target.value)}
						/>
					</div>
				</div>
			)}
			<button className='note-button' onClick={onDelete}>
				{t("userFeatures.delete")}
			</button>
		</Popup>
	);
};

export default NotePopup;
