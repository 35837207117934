import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TopNav } from "../../components";
import { TopNavLogo, TopNavButton, TopNavDropDown } from "../../components/TopNav/TopNav";
import { useUpdates } from "../../hooks";
import UpdatesModal from "../UpdatesModal";
import Maps from "./Maps";
import MarkerGroups from "./MarkerGroups";
import SearchBox from "./SearchBox";
import ShowHideButton from "./ShowHideButton";

const MobileNav: React.FC = () => {
	const { setOpen } = useUpdates();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [activeTab, setActiveTab] = useState<string | undefined>();
	const { t } = useTranslation();

	const getDropDownContent = () => {
		switch (activeTab) {
			case "maps":
				return <Maps />;
			case "filters":
				return (
					<>
						<ShowHideButton />
						<SearchBox />
						<MarkerGroups />
					</>
				);
			default:
				return <></>;
		}
	};

	const handleTabClick = (name: string) => {
		if (activeTab === name) {
			setDropdownOpen(false);
			setTimeout(() => setActiveTab(undefined), 500);

			return;
		}

		setActiveTab(name);
		setDropdownOpen(true);
	};

	return (
		<>
			<TopNav>
				<TopNavLogo />
				<div className='flex-1'></div>
				<TopNavButton onClick={() => setOpen(true)}>{t("sidebar.updates")}</TopNavButton>
				<TopNavButton onClick={() => handleTabClick("maps")}>{t("sidebar.maps")}</TopNavButton>
				<TopNavButton onClick={() => handleTabClick("filters")}>
					{t("sidebar.filters")}
				</TopNavButton>
				<TopNavDropDown isOpen={dropdownOpen}>{getDropDownContent()}</TopNavDropDown>
			</TopNav>
			<UpdatesModal />
		</>
	);
};

export default MobileNav;
