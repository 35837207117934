import { atom, selector } from "recoil";
import { getMapData, getMaps } from "../services/api";
import {
	ActiveMarkerType,
	GroupedMap,
	MapDataType,
	MapHistoryType,
	SidePopupContent,
} from "../types/MapTypes";

export const MapsListState = atom<GroupedMap[]>({
	key: "MapsListState",
	default: getMaps(),
});

export const CurrentMapState = atom<string>({
	key: "CurrentMapState",
	default: undefined,
});

export const CurrentMapDataState = selector<MapDataType | undefined>({
	key: "CurrentMapDataState",
	get: ({ get }) => {
		let currentMap = get(CurrentMapState);
		if (currentMap) {
			return getMapData(currentMap);
		}
	},
});

export const HiddenCategoriesState = atom<string[]>({
	key: "HiddenCategoriesState",
	default: [],
});

export const HiddenMarkersState = atom<string[]>({
	key: "HiddenMarkersState",
	default: [],
});

export const ActiveMarkerState = atom<string | null>({
	key: "ActiveMarker",
	default: null,
});

export const PinLinkModeState = atom<boolean>({
	key: "PinLinkModeState",
	default: false,
});

export const TopNavHeightState = atom<number>({
	key: "TopNavHeightState",
	default: 0,
});

export const SidePopupContentState = atom<SidePopupContent | null>({
	key: "SidePopupContentState",
	default: null,
});

export const SidePopupModeState = atom<boolean>({
	key: "SidepopupModeState",
	default: false,
});

export const MapNavigationHistoryState = atom<MapHistoryType[]>({
	key: "MapNavigationHistoryState",
	default: [],
});

export const ClickedMarkerState = atom<ActiveMarkerType | null>({
	key: "ClickedMarkerState",
	default: null,
});

export const NotesActiveState = atom<string | null>({
	key: "NotesActiveState",
	default: null,
});
