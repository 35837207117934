import L, { LatLngExpression } from "leaflet";
import React, { forwardRef, useEffect, useRef } from "react";
import { Polyline, PolylineProps, useMap } from "react-leaflet";
import "leaflet-polylinedecorator";
import classNames from "classnames";
import { css } from "@emotion/css";

interface PolylineDecoratorProps extends PolylineProps {
	type?: "simple" | "arrow" | "numbered";
}

const useCombinedRefs = (...refs: any) => {
	const targetRef = React.useRef<L.Polyline>(null);

	React.useEffect(() => {
		refs.forEach((ref: any) => {
			if (!ref) return;

			if (typeof ref === "function") {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
};

const PolylineDecorator = forwardRef<L.Polyline, PolylineDecoratorProps>(
	({ children, type, ...restProps }, ref) => {
		const lineRef = useRef<L.Polyline>(null);
		const combinedRef = useCombinedRefs(ref, lineRef);

		const map = useMap();
		const { positions, color } = restProps;

		useEffect(() => {
			combinedRef.current?.setStyle({ dashArray: restProps.dashArray });
		}, [restProps.dashArray]);

		useEffect(() => {
			if (type === "numbered") {
				let className = classNames(
					"border-2 flex text-center rounded-full border-box",
					css`
						color: ${color};
						border-color: ${color};
					`
				);

				let marks = positions.map((point, idx) => {
					let m = L.marker(point as LatLngExpression, {
						icon: L.divIcon({
							html: `<span>${(idx + 1).toString()}</span>`,
							className: className,
							iconSize: [25, 25],
						}),
					});
					map.addLayer(m);
					return m;
				});

				return () => {
					marks.map((m) => map.removeLayer(m));
				};
			}
		}, [combinedRef, map, restProps.color, type, positions]);

		useEffect(() => {
			let arrow = (L as any).polylineDecorator(combinedRef.current, {
				patterns: [
					{
						offset: "100%",
						repeat: 0,
						symbol: (L as any).Symbol.arrowHead({
							pixelSize: 10,
							headAngle: 90,
							polygon: false,
							pathOptions: { stroke: true, color: restProps.color },
						}),
					},
				],
			});
			if (type === "arrow") {
				arrow.addTo(map);
			} else {
				if (map.hasLayer(arrow)) {
					map.removeLayer(arrow);
				}
			}

			return () => {
				map.hasLayer(arrow) && map.removeLayer(arrow);
			};
		}, [combinedRef, map, restProps.color, type]);

		if (type === "numbered") return null;

		return (
			<Polyline ref={combinedRef} {...restProps}>
				{children}
			</Polyline>
		);
	}
);

export default PolylineDecorator;
