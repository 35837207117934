import { LatLng, LatLngExpression } from "leaflet";
import React from "react";
import { Polygon } from "react-leaflet";
import { flipLatLng, flipPoly } from "../../helpers";
import { MapCategoryType, MapFeatureType } from "../../types/MapTypes";
import Marker from "../Marker/Marker";
import PolylineDecorator from "../Polyline/PolylineDecorator";

type FeatureProps = {
	feature: MapFeatureType;
	category: MapCategoryType;
	tileMode: boolean;
};

const Feature: React.FC<FeatureProps> = ({ feature, category, tileMode }) => {
	const getFeature = () => {
		if (feature.type === "Point") {
			return getMarker(feature, category);
		} else if (feature.type === "Polygon") {
			return getPolygon(feature, category);
		} else {
			return getLineString(feature, category);
		}
	};

	const getMarker = (marker: MapFeatureType, category: MapCategoryType) => {
		let position = flipLatLng(marker.coordinates as LatLng, tileMode);
		return <Marker position={position} category={category} marker={marker} />;
	};

	const getPolygon = (marker: MapFeatureType, category: MapCategoryType) => {
		let positions = flipPoly(marker.coordinates, tileMode);
		return (
			<Polygon
				positions={positions as LatLngExpression[][]}
				color={marker.properties.primary_color}
			/>
		);
	};

	const getLineString = (feature: MapFeatureType, category: MapCategoryType) => {
		let positions = flipPoly(feature.coordinates, tileMode);
		return (
			<PolylineDecorator
				positions={positions as LatLngExpression[]}
				color={feature.properties.primary_color}
				dashArray={feature.properties.path_spacing}
				type={feature.properties.line_head}
			/>
		);
	};
	return <>{getFeature()}</>;
};

export default Feature;
