import React, { useMemo, useState } from "react";
import { CommentType } from "../../types/commentTypes";
import { FiInfo, FiThumbsDown, FiThumbsUp, FiTrash } from "react-icons/fi";
import { UserType } from "../../types/userTypes";
import { IconType } from "react-icons/lib";
import classNames from "classnames";
import useComments from "../../hooks/useComments";
import { toast } from "react-toastify";
import { toastOptions } from "../../config";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getYtThumbnail, ytVideoID } from "../../helpers";
import { useMediaModal } from "../../hooks";
import { ReportModal } from "../../components";

type CommentItemProps = {
	item: CommentType;
	user: UserType | null;
	isFirst?: boolean;
};

interface CommentButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	icon: IconType;
}

const CommentButton: React.FC<CommentButtonProps> = (props) => {
	const { children, icon: Icon, className, ...restProps } = props;

	const cls = classNames("flex items-center px-1.5 rounded transition-color", className);
	return (
		<button className={cls} {...restProps}>
			<Icon className='mr-1.5' />
			{children}
		</button>
	);
};
interface CommentMediaProps {
	url: string;
	full?: boolean;
	fullLink?: string | undefined;
}

const CommentMedia: React.FC<CommentMediaProps> = (props) => {
	const { url, full, fullLink } = props;

	const { openModal } = useMediaModal();

	const type = useMemo(() => {
		if (url.includes("youtube.com") || url.includes("youtu.be")) {
			return "iframe";
		}

		if (url.includes("imgur.com")) {
			return "image";
		}
	}, [url]);

	const showMedia = () => {
		let embed;
		if (type === "iframe") {
			embed = "//www.youtube.com/embed/" + ytVideoID(url);
		} else {
			embed = fullLink || "";
		}
		openModal(embed, type);
	};

	const getMediaLink = () => {
		if (type === "iframe") {
			return getYtThumbnail(url, full ? "max" : "sd") || "";
		}

		if (full) {
			return fullLink;
		}
		return url;
	};

	return (
		<div
			className={classNames(
				"w-16 aspect-square bg-white/30 transition-all hover:scale-105 rounded overflow-hidden cursor-pointer",
				{
					"!w-auto !justify-self-stretch mx-4 mt-2 !aspect-auto": full,
				}
			)}
			onClick={showMedia}
		>
			<img className='w-full h-full object-cover' src={getMediaLink()} alt='comment_media' />
		</div>
	);
};

const CommentItem: React.FC<CommentItemProps> = (props) => {
	const { item: comment, user, isFirst } = props;
	const { deleteComment, vote, report } = useComments();
	const { t } = useTranslation("", { keyPrefix: "comments" });
	const [reportOpen, setReportOpen] = useState(false);
	const onDelete = async () => {
		const shouldDelete = window.confirm(t("postDeleteConfirm"));
		if (shouldDelete) {
			const res = await deleteComment(comment.id);
			if (res && res.status === "success") {
				toast.success(t("postDeleteSuccess"), toastOptions);
			} else {
				toast.error(t("postDeleteError"), toastOptions);
			}
		}
	};

	const getDate = (date: string) => {
		return moment(date).format("LL").toString();
	};

	const getMediaLink = () => {
		if (comment.youtube_link) {
			return comment.youtube_link;
		}

		if (comment.imgur_link) {
			return `https://i.imgur.com/${comment.imgur_link}s.png`;
		}
		return "";
	};

	const getFullLink = () => {
		if (comment.imgur_link) {
			return `https://i.imgur.com/${comment.imgur_link}.png`;
		}
	};

	const handleReport = (content: string) => {
		setReportOpen(false);
		report(comment.id, content);
	};

	// TODO: Extract to component
	return (
		<>
			<div className='flex flex-col bg-theme-700 rounded'>
				<div className='px-4 py-3 pb-0 font-bold'>
					{comment.author_username}
					<span className='ml-1 font-normal text-sm'>{getDate(comment.createdAt)}</span>
				</div>
				{isFirst && (comment.imgur_link || comment.youtube_link) && (
					<CommentMedia url={getMediaLink()} full fullLink={getFullLink()} />
				)}
				<div className='px-4 py-2 flex'>
					<div className='flex-1'>{comment.content}</div>
					{!isFirst && (comment.imgur_link || comment.youtube_link) && (
						<CommentMedia url={getMediaLink()} fullLink={getFullLink()} />
					)}
				</div>
				<div className='px-4 pb-3 flex items-center justify-between'>
					<div className='flex space-x-2'>
						<CommentButton
							icon={FiThumbsUp}
							className='hover:bg-lime-600'
							onClick={() => vote(comment.id, "1")}
						>
							{comment.likes}
						</CommentButton>
						<CommentButton
							icon={FiThumbsDown}
							className='hover:bg-red-500'
							onClick={() => vote(comment.id, "-1")}
						>
							{comment.dislikes}
						</CommentButton>
					</div>
					<div>
						{user?.id === comment.user_id ? (
							<CommentButton icon={FiTrash} className='hover:bg-red-500' onClick={onDelete}>
								{t("postDelete")}
							</CommentButton>
						) : (
							<CommentButton
								icon={FiInfo}
								className='hover:bg-red-500'
								onClick={() => setReportOpen(true)}
							>
								{t("postReport")}
							</CommentButton>
						)}
					</div>
				</div>
			</div>
			<ReportModal
				isOpen={reportOpen}
				onClose={() => setReportOpen(false)}
				title={t("postReport")}
				buttonText={t("postReport")}
				onSubmit={(c) => handleReport(c)}
			/>
		</>
	);
};

export default CommentItem;
