import React from "react";
import { useTranslation } from "react-i18next";
import { FiInfo } from "react-icons/fi";
import { Dropdown, Loader, MarkerOverview } from "../../components";
import { useLogin } from "../../hooks";
import useComments from "../../hooks/useComments";
import { CommentType } from "../../types";
import { ActiveMarkerType } from "../../types/MapTypes";
import AddComment from "./AddComment";
import CommentItem from "./CommentItem";

type CommentsListProps = {
	error: string | null;
	loading: boolean;
	comments: CommentType[];
	clickedMarker: ActiveMarkerType | null;
};

const CommentsList: React.FC<CommentsListProps> = (props) => {
	const { error, loading, comments, clickedMarker } = props;

	const { user } = useLogin();
	const { t } = useTranslation("", { keyPrefix: "comments" });
	if (!clickedMarker) {
		return (
			<div className='mt-5 px-3 py-3 flex items-center bg-orange-400 text-black rounded'>
				<FiInfo className='mr-2' size={20} />
				<div>{t("postMarkerSelect")}</div>
			</div>
		);
	}

	if (!loading && !error && comments.length === 0) {
		return (
			<div className='mt-5 px-3 py-3 flex items-center bg-orange-400 text-black rounded'>
				<FiInfo className='mr-2' size={20} />
				<div>{t("postMarkerNoComments")}</div>
			</div>
		);
	}

	if (error) {
		return (
			<div className='mt-5 px-3 py-3 flex items-center bg-orange-400 text-black rounded'>
				<FiInfo className='mr-2' size={20} />
				<div>{error}</div>
			</div>
		);
	}

	return (
		<div className='mt-5 space-y-3'>
			<div className='flex justify-end'>
				<Dropdown
					items={[
						{ name: t("postByMe"), value: "my" },
						{ name: t("postAll"), value: "all" },
					]}
					onChange={() => alert("changed filter")}
				/>
			</div>
			{loading && <Loader />}
			{comments &&
				comments.map((comment, idx) => (
					<CommentItem item={comment} user={user} isFirst={idx === 0} />
				))}
		</div>
	);
};

const CommentsPanel: React.FC = () => {
	const { error, loading, comments, clickedMarker, errorType } = useComments();
	const { isLoggedIn } = useLogin();
	const { t } = useTranslation();
	return (
		<div className='text-white'>
			{clickedMarker && <MarkerOverview marker={clickedMarker} copyMessage={t("copied")} />}
			{!loading && errorType !== "category_disabled" && isLoggedIn && clickedMarker && (
				<AddComment />
			)}
			<CommentsList
				error={error}
				loading={loading}
				comments={comments}
				clickedMarker={clickedMarker}
			/>
		</div>
	);
};

export default CommentsPanel;
