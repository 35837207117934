import React from "react";
import { useTranslation } from "react-i18next";
import { useMaps } from "../../hooks";

const ShowHideButton: React.FC = () => {
	const { toggleAll } = useMaps();

	const { t } = useTranslation();

	return (
		<button
			onClick={toggleAll}
			className='bg-transparent mt-3 border border-lime-600 px-5 py-2 text-white rounded-sm hover:bg-lime-500/10'
		>
			{t("sidebar.showHideAll")}
		</button>
	);
};

export default ShowHideButton;
