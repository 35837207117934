import { css } from "@emotion/css";
import _ from "lodash";
import { isArray } from "lodash";
import { MapDataType, MapFeatureType } from "../types/MapTypes";

export const getMarkersFromData = (data: MapDataType | MapDataType[]): MapFeatureType[] => {
	const fn = _.spread<MapFeatureType[]>(_.union);

	if (isArray(data)) {
		let res: any = data.map((row) => getMarkersFromData(row));
		let flat = _.flatten(fn(res));
		return flat.filter((i) => i !== undefined);
	}

	let d = data?.groups.map((group) => {
		return group.categories?.map((cat) => {
			if (cat.data) {
				return cat.data.map((row: any) => {
					return { ...row, cat_image: cat.icon_image };
				});
			}
		});
	});

	return _.flatten(fn(d));
};

export const getTooltipStyles = (color: string, background: string) => {
	return css`
		color: ${color} !important;
		background: ${background} !important;
		&.place-top:after {
			border-top-color: ${background} !important;
		}
		&.place-bottom:after {
			border-bottom-color: ${background} !important;
		}
	`;
};

export const ytVideoID = (url: string) => {
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	var match = url.match(regExp);
	return match && match[7].length == 11 ? match[7] : false;
};

export const getYtThumbnail = (url: string, quality: string) => {
	const videoid = ytVideoID(url);

	if (!videoid) return false;

	switch (quality) {
		case "max":
			return `https://i1.ytimg.com/vi/${videoid}/maxresdefault.jpg`;
		case "hq":
			return `https://i1.ytimg.com/vi/${videoid}/mqdefault.jpg`;
		case "mq":
			return `https://i1.ytimg.com/vi/${videoid}/hqdefault.jpg`;
		case "sd":
			return `https://i1.ytimg.com/vi/${videoid}/sddefault.jpg`;
		case "default":
			return `https://i1.ytimg.com/vi/${videoid}/default.jpg`;
		default:
			return "Please provide proper input for quality (max,hq,mq,sd,default)";
	}
};

export * from "./coordinates";
export * from "./refreshToken";
