import { Listbox } from "@headlessui/react";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import Dropdown from "../Dropdown/Dropdown";

type ClockProps = {
	timezones: string[];
	localTimeTitle: string;
	serverTimeTitle: string;
};

const Clock: React.FC<ClockProps> = ({ timezones, localTimeTitle, serverTimeTitle }) => {
	const [time, setTime] = useState<string | undefined>();
	const [serverTimezone, setServerTimezone] = useState<string | undefined>();

	useEffect(() => {
		let timer = window.setInterval(function () {
			setTime(moment().format("h : mm : ss a").toString());
		}, 1000);

		return () => {
			window.clearInterval(timer);
		};
	}, []);

	const getTimezoneTime = (timezone: string) => {
		return moment().tz(timezone).format("h : mm : ss a");
	};

	return (
		<div className='flex text-white space-x-2'>
			<div className='flex flex-col items-center justify-center flex-1 bg-black/10 py-2 rounded'>
				<div className='text-center text-base font-bold'>{localTimeTitle}</div>
				<div>{time}</div>
			</div>
			<div className='flex flex-col items-center justify-center flex-1 bg-black/10 py-2 rounded'>
				<Dropdown
					onChange={(item) => setServerTimezone(item.value)}
					items={timezones.map((t) => ({ name: t, value: t }))}
					renderButton={(selected) => (
						<Listbox.Button className=''>
							<div className='text-center text-base font-bold'>{serverTimeTitle}</div>
							<div>{getTimezoneTime(selected.value)}</div>
							<div className='text-xs'>{selected.value}</div>
						</Listbox.Button>
					)}
				/>
			</div>
		</div>
	);
};

export default Clock;
