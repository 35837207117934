import axios, { AxiosRequestConfig } from "axios";
import _ from "lodash";
import { base_url, dev_base_url } from "../config";
import { zeroOrOne } from "../types";
import {
	AddUserFeaturesResponse,
	AddUserVisitedResponse,
	DeleteUserFeatureResponse,
	GetUserFeaturesResponse,
	GetUserProgressResponse,
	GetUserVisitedResponse,
	LoginResponse,
	RenewTokenResponse as VerifyTokenResponse,
	SaveUserNameResponse,
	UpdateUserFeatureResponse,
} from "../types/userTypes";

export let computed_url = process.env.NODE_ENV === "development" ? dev_base_url : base_url;

export const loginUser = (code: string) =>
	new Promise<LoginResponse>((resolve, reject) => {
		axios
			.get<LoginResponse>(`${computed_url}/usersApi/login?code=${code}`)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const verifyUserToken = (token: string) =>
	new Promise<VerifyTokenResponse>((resolve, reject) => {
		axios
			.get<VerifyTokenResponse>(`${computed_url}/usersApi/verifyToken`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const saveUsername = (token: string, username: string) => {
	let data = new FormData();
	data.append("username", username);

	return new Promise<SaveUserNameResponse>((resolve, reject) => {
		let config: AxiosRequestConfig = {
			method: "POST",
			url: `${computed_url}/usersApi/changeUsername`,
			headers: { Authorization: `Bearer ${token}` },
			data,
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

export const getUserVisited = (token: string) =>
	new Promise<GetUserVisitedResponse>((resolve, reject) => {
		axios
			.get<GetUserVisitedResponse>(`${computed_url}/userProgressApi/getVisitedMarkers`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const addUserVisited = (token: string, markersIds: string[]) => {
	let data = new FormData();
	data.append("markers", markersIds.join(","));

	return new Promise<AddUserVisitedResponse>((resolve, reject) => {
		let config: AxiosRequestConfig = {
			method: "POST",
			url: `${computed_url}/userProgressApi/addVisitedMarker`,
			headers: { Authorization: `Bearer ${token}` },
			data,
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

export const deleteUserVisited = (token: string, markersIds: string[]) => {
	let data = new FormData();
	data.append("markers", markersIds.join(","));

	return new Promise<AddUserVisitedResponse>((resolve, reject) => {
		let config: AxiosRequestConfig = {
			method: "POST",
			url: `${computed_url}/userProgressApi/deleteVisitedMarkers`,
			headers: { Authorization: `Bearer ${token}` },
			data,
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

export const getUserProgress = (token: string) =>
	new Promise<GetUserProgressResponse>((resolve, reject) => {
		axios
			.get<GetUserProgressResponse>(`${computed_url}/userProgressApi/getUserProgress`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getUserMapProgress = (token: string, mapId: string) =>
	new Promise<GetUserProgressResponse>((resolve, reject) => {
		axios
			.get<GetUserProgressResponse>(`${computed_url}/userProgressApi/getUserMapProgress/${mapId}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getUserMapGroupProgress = (token: string, mapId: string) =>
	new Promise<GetUserProgressResponse>((resolve, reject) => {
		axios
			.get<GetUserProgressResponse>(
				`${computed_url}/userProgressApi/getUserMapGroupProgress/${mapId}`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export const getUserFeatures = (token: string) =>
	new Promise<GetUserFeaturesResponse>((resolve, reject) => {
		axios
			.get<GetUserFeaturesResponse>(`${computed_url}/userFeaturesApi/list`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

export type AddUserFeatureData = {
	type: "marker" | "polyline" | "polygon";
	map_id: string;
	geometry: string;
	tiles_coordinates: boolean;
};

export const addUserFeature = (token: string, addData: AddUserFeatureData) => {
	let data = new FormData();
	_.mapKeys(addData, (value, key) => {
		data.append(key, value.toString());
	});

	return new Promise<AddUserFeaturesResponse>((resolve, reject) => {
		let config: AxiosRequestConfig = {
			method: "POST",
			url: `${computed_url}/userFeaturesApi/add`,
			headers: { Authorization: `Bearer ${token}` },
			data,
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

export const deleteUserFeature = (token: string, featureId: string) =>
	new Promise<DeleteUserFeatureResponse>((resolve, reject) => {
		axios
			.get<DeleteUserFeatureResponse>(`${computed_url}/userFeaturesApi/delete/${featureId}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});

type UpdateFeatureData = {
	notes: string;
	geometry: any;
};

export const updateUserFeature = (
	token: string,
	featureId: string,
	updateData: UpdateFeatureData
) => {
	let data = new FormData();
	_.mapKeys(updateData, (value, key) => {
		data.append(key, value);
	});

	return new Promise<UpdateUserFeatureResponse>((resolve, reject) => {
		let config: AxiosRequestConfig = {
			method: "POST",
			url: `${computed_url}/userFeaturesApi/edit/${featureId}`,
			headers: { Authorization: `Bearer ${token}` },
			data,
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};
