import React, { useEffect, useState } from "react";
import Control from "react-leaflet-custom-control";
import { ControlButton, Loader, ProgressPopup, UserProgress } from "../../components";
import { FiBarChart2 } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { useLogin, useMaps } from "../../hooks";
import { useQuery } from "react-query";
import { getUserMapGroupProgress, getUserMapProgress } from "../../services/userApi";
import { isArray } from "lodash";

const ProgressControl: React.FC = () => {
	const { t } = useTranslation();
	const [isOpen, setOpen] = useState(false);
	const [active, setActive] = useState(0);
	const { currentMap, currentMapData, hiddenMarkers } = useMaps();
	const { token } = useLogin();
	const {
		data: mapProgress,
		error: mapProgressError,
		isLoading: mapProgressLoading,
		isFetching: mapProgressIsFetching,
		refetch: mapProgressRefetch,
	} = useQuery(`map.${currentMap}.progress`, () => getUserMapProgress(token || "", currentMap), {
		enabled: false,
	});

	const {
		data: mapGroupProgress,
		error: mapGroupProgressError,
		isLoading: mapGroupProgressLoading,
		isFetching: mapGroupProgressIsFetching,
		refetch: mapGroupProgressRefetch,
	} = useQuery(
		`map.${currentMap}.mapGroupProgress`,
		() => getUserMapGroupProgress(token || "", currentMap),
		{ enabled: false }
	);

	useEffect(() => {
		!mapProgressIsFetching && mapProgressRefetch();
		!mapGroupProgressIsFetching && mapGroupProgressRefetch();
	}, [hiddenMarkers, isOpen, active]);

	const onChangeTab = (index: number) => {
		setActive(index);
	};

	return (
		<Control position='topright' style={{ flexDirection: "row", alignSelf: "flex-end" }}>
			<ControlButton tooltip={t("userProgress.fbTooltip")} onClick={() => setOpen(!isOpen)}>
				<FiBarChart2 />
			</ControlButton>
			<ProgressPopup isOpen={isOpen} title={t("userProgress.title")} onClose={() => setOpen(false)}>
				<Tab.Group selectedIndex={active} onChange={onChangeTab}>
					<Tab.List className='sidebar-tabs-list'>
						<Tab as={React.Fragment}>
							{({ selected }) => {
								let btnCls = classNames("tab-item", { selected: selected });
								return <button className={btnCls}>{t("userProgress.map")}</button>;
							}}
						</Tab>
						{currentMapData.getValue()?.map_group_id !== "0" && (
							<Tab as={React.Fragment}>
								{({ selected }) => {
									let btnCls = classNames("tab-item", { selected: selected });
									return <button className={btnCls}>{t("userProgress.group")}</button>;
								}}
							</Tab>
						)}
					</Tab.List>
					<Tab.Panels className='sidebar-tabs-panels'>
						<Tab.Panel className='tab-panel'>
							{mapProgressLoading && <Loader />}
							{mapProgressError && t("error")}
							{!isArray(mapProgress) ? t("error") : <UserProgress title='' data={mapProgress} />}
							{isArray(mapProgress) &&
								mapProgress.length === 0 &&
								t("userProgress.noProgressMessage")}
						</Tab.Panel>
						<Tab.Panel className='tab-panel'>
							{mapGroupProgressLoading && <Loader />}
							{mapGroupProgressError && t("error")}
							{!isArray(mapGroupProgress) ? (
								t("error")
							) : (
								<UserProgress title='' data={mapGroupProgress} />
							)}
							{isArray(mapGroupProgress) &&
								mapGroupProgress.length === 0 &&
								t("userProgress.noProgressMessage")}
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>
			</ProgressPopup>
		</Control>
	);
};

export default ProgressControl;
