import React, { useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../../config";
import useComments from "../../hooks/useComments";
import { AddComment as AddCommentComponent } from "../../components";
import { FiArrowRight } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { SubmitAddCommentType } from "../../components/AddComment/AddComment";
import { useQuery } from "react-query";
import { getTranslations } from "../../services/api";
import { isArray } from "lodash";
import { useRecoilState } from "recoil";
import { CommentsLanguageState } from "../../store";

const AddComment: React.FC = () => {
	const [isLoading, setLoading] = useState(false);
	const { addComment, refetchComments } = useComments();
	const { t } = useTranslation("", { keyPrefix: "comments" });
	const { data: languages } = useQuery("translations", getTranslations);
	const [commentsLanguage, setCommentsLanguage] = useRecoilState(CommentsLanguageState);

	const handleSubmit = async (e: SubmitAddCommentType) => {
		const { content } = e;
		if (content === "") return false;
		setLoading(true);
		const res = await addComment(content);
		if (!res) return false;
		if (res.status === "success") {
			toast.success(t("addSuccess"), toastOptions);
			refetchComments();
			return true;
		} else {
			if (res.status === "error" && res.error_type === "user_banned") {
				toast.error(t("addUserBanned"), toastOptions);
			} else {
				toast.error(t("addError"), toastOptions);
			}
		}

		setLoading(false);
		return false;
	};

	return (
		<AddCommentComponent
			title={t("postTitle")}
			isLoading={isLoading}
			onSubmit={handleSubmit}
			language={commentsLanguage}
			onLanguageChange={(l) => setCommentsLanguage(l)}
			languages={isArray(languages) ? languages : []}
			buttonContent={
				isLoading ? (
					t("postButtonLoading")
				) : (
					<>
						{t("postButton")} <FiArrowRight className='ml-1' size={16} />
					</>
				)
			}
		/>
	);
};

export default AddComment;
