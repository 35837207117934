import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Modal } from "../components";
import { useUpdates } from "../hooks";

const UpdatesModal: React.FC = () => {
	const { isOpen, setOpen, updates } = useUpdates();
	const { t } = useTranslation();
	useEffect(() => {
		if (isOpen && !updates.isFetched) {
			updates.refetch();
		}
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={() => setOpen(false)}>
			{updates.isLoading && <Loader />}
			{updates.isError && <div className='text-red-500'>{t("error")}</div>}
			{updates.isFetched && (
				<div
					className='no-styles'
					dangerouslySetInnerHTML={{ __html: updates.data?.update || "" }}
				/>
			)}
		</Modal>
	);
};

export default UpdatesModal;
