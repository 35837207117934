import React from "react";
import { Button, Loader } from "../../components";
import { FaDiscord, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { useSiteOptions, useUpdates } from "../../hooks";
import { useTranslation } from "react-i18next";
const HeaderButtons: React.FC = () => {
	const { setOpen } = useUpdates();
	const { siteOptions } = useSiteOptions();
	const { t } = useTranslation();

	const getButtons = () => {
		const { state, contents: siteOptionsData } = siteOptions;
		if (state === "loading") {
			return <Loader />;
		}

		if (state === "hasError" || !siteOptionsData) {
			return null;
		}

		const { facebook_link, twitter_link, instagram_link, discord_link } = siteOptionsData;

		return (
			<>
				{discord_link && (
					<Button link={discord_link} newWindow>
						<FaDiscord />
					</Button>
				)}
				{facebook_link && (
					<Button link={facebook_link} newWindow>
						<FaFacebook />
					</Button>
				)}
				{instagram_link && (
					<Button link={instagram_link} newWindow>
						<FaInstagram />
					</Button>
				)}
				{twitter_link && (
					<Button link={twitter_link} newWindow>
						<FaTwitter />
					</Button>
				)}
			</>
		);
	};

	return (
		<div className='flex space-x-2.5 justify-center mt-3 mb-3'>
			<Button onClick={() => setOpen(true)}>{t("sidebar.updates")}</Button>
			{getButtons()}
		</div>
	);
};

export default HeaderButtons;
