import { Portal } from "@headlessui/react";
import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaQuestion } from "react-icons/fa";
import Control from "react-leaflet-custom-control";
import { useQuery } from "react-query";
import { ControlButton, Loader, Modal } from "../../components";
import { getInfoModalOptions } from "../../services/api";

const InfoModalControl: React.FC = () => {
	const { isFetched, isLoading, isError, data, refetch } = useQuery(
		"infoModalOptions",
		getInfoModalOptions,
		{ enabled: false }
	);
	const [isOpen, setOpen] = useState(false);
	const { t } = useTranslation();

	useLayoutEffect(() => {
		if (!isFetched && !data && isOpen) {
			refetch();
		}
	}, [isOpen]);

	return (
		<>
			<Control position='topleft'>
				<ControlButton onClick={() => setOpen(true)} tooltip={t("controls.tips.infoModal")}>
					<FaQuestion />
				</ControlButton>
			</Control>
			<Portal>
				<Modal isOpen={isOpen} onClose={() => setOpen(false)}>
					{isLoading && <Loader className='my-10' />}
					{isError && (
						<div className='my-2 text-center text-red-500'>Error Fetching Info Modal</div>
					)}
					{isFetched && (
						<div
							className='no-styles'
							dangerouslySetInnerHTML={{ __html: data?.infoModalContent || "" }}
						></div>
					)}
				</Modal>
			</Portal>
		</>
	);
};

export default InfoModalControl;
