import { useEffect } from "react";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import {
	ActiveMarkerState,
	CurrentMapDataState,
	CurrentMapState,
	HiddenCategoriesState,
	HiddenMarkersState,
	MapNavigationHistoryState,
	MapsListState,
} from "../store/mapState";
import _ from "lodash";
import { MapGroupType, MapHistoryType } from "../types/MapTypes";
import { v4 as uuid } from "uuid";
import { useQuery } from "react-query";
import { addUserVisited, deleteUserVisited, getUserVisited } from "../services/userApi";
import useLogin from "./useLogin";
import { toast } from "react-toastify";
import { toastOptions } from "../config";
import { useTranslation } from "react-i18next";

const useMaps = () => {
	const maps = useRecoilValueLoadable(MapsListState);
	const { token } = useLogin();
	const currentMapData = useRecoilValueLoadable(CurrentMapDataState);
	const [currentMap, _setCurrentMap] = useRecoilState(CurrentMapState);

	const [hiddenCategories, setHiddenCategories] = useRecoilState(HiddenCategoriesState);
	const [hiddenMarkers, setHiddenMarkers] = useRecoilState(HiddenMarkersState);
	const [activeMarker, _setActiveMarker] = useRecoilState(ActiveMarkerState);
	const [mapHistory, setMapHistory] = useRecoilState(MapNavigationHistoryState);
	const { data, refetch, isFetching } = useQuery(
		`userVisited.${token}`,
		() => getUserVisited(token || ""),
		{
			enabled: false,
		}
	);
	const { t } = useTranslation();

	useEffect(() => {
		!isFetching && token && refetch();
	}, [hiddenMarkers]);

	useEffect(() => {
		if (data?.visitedMarkers && data.visitedMarkers.length > 0) {
			let hiddenMarkers = data.visitedMarkers.map((vM) => vM.marker_id);
			setHiddenMarkers(hiddenMarkers);
		}
	}, [data]);

	useEffect(() => {
		let url = new URL(window.location.toString());
		if (url.searchParams.get("marker_id")) setActiveMarker(url.searchParams.get("marker_id"));
	}, []);

	// Set current map to first map;
	useEffect(() => {
		if (maps.state === "hasValue" && !currentMap) {
			setDefaultMap();
		}
	}, [maps, currentMap]);

	const setDefaultMap = () => {
		if (currentMap) return;

		let urlMap = getUrlMap();
		if (urlMap) {
			return setCurrentMap(urlMap, false);
		}

		if (maps.contents) {
			const defaultMap = getDefaultMap();
			setCurrentMap(defaultMap);
		}
	};

	const getDefaultMap = () => {
		if (maps.contents) {
			let firstMap = maps.contents[0].maps[0].id;
			return firstMap;
		}
	};

	// Map
	const setCurrentMap = (mapId: string, reset = true, history = false) => {
		const url = new URL(window.location.toString());
		if (reset) {
			url.search = "";
			setActiveMarker(null);
		}

		if (history) {
			pushToHistory({ id: uuid(), mapId: currentMap });
		}

		url.searchParams.set("map", mapId);
		window.history.pushState({}, "", url.toString());
		_setCurrentMap(mapId);
	};

	const getUrlMap = () => {
		const url = new URL(window.location.toString());
		return url.searchParams.get("map");
	};

	// Push to map history
	const pushToHistory = (h: MapHistoryType) => {
		setMapHistory([...mapHistory, h]);
	};

	// Go back one step
	const goBack = () => {
		if (mapHistory.length) {
			let lastItem = mapHistory[mapHistory.length - 1];
			setMapHistory(_.without(mapHistory, lastItem));
			setCurrentMap(lastItem.mapId);
			if (lastItem.markerId) {
				setActiveMarker(lastItem.markerId);
			}
		} else {
			setCurrentMap(getDefaultMap());
		}
	};

	// Marker Groups
	const toggleGroup = (group: MapGroupType) => {
		let categoriesIds = group.categories?.map((cat) => cat.id);
		let hiddenOfGroup = _.intersection(hiddenCategories, categoriesIds);

		if (!categoriesIds) return;

		if (categoriesIds.length > hiddenOfGroup.length) {
			setHiddenCategories([..._.without(hiddenCategories, ...hiddenOfGroup), ...categoriesIds]);
		} else {
			setHiddenCategories([..._.without(hiddenCategories, ...categoriesIds)]);
		}
	};

	// Categories
	const toggleCategory = (categoryId: string) => {
		if (isCategoryHidden(categoryId)) {
			setHiddenCategories(_.without(hiddenCategories, categoryId));
			return;
		}
		setHiddenCategories([...hiddenCategories, categoryId]);
	};

	const isCategoryHidden = (categoryId: string) => {
		return _.includes(hiddenCategories, categoryId);
	};

	// Markers
	const toggleMarker = (markerId: string, sibling_identifier: string) => {
		let mapData = currentMapData.getValue();
		let siblings: string[] = [];

		if (mapData && sibling_identifier !== "") {
			mapData.groups.forEach((group) => {
				group.categories?.forEach((cat) => {
					cat.data?.forEach((marker) => {
						if (marker.properties.sibling_identifier === sibling_identifier) {
							siblings.push(marker.properties.id);
						}
					});
				});
			});
		}

		if (siblings.length === 0) {
			siblings.push(markerId);
		}

		let includesAny = _.intersection(hiddenMarkers, siblings).length !== 0;

		if (includesAny) {
			// removing
			syncVisitedMakers(siblings, "delete");
			setHiddenMarkers(_.without(hiddenMarkers, ...siblings));
		} else {
			// Adding
			syncVisitedMakers(siblings, "add");
			setHiddenMarkers(_.union(hiddenMarkers, siblings));
		}
	};

	const syncVisitedMakers = async (markerIds: string[], action: "add" | "delete") => {
		if (!token) {
			return;
		}

		if (action === "add") {
			let added = await addUserVisited(token, markerIds);
			if (added.status === "error") {
				toast.error(t("userProgress.syncError"), toastOptions);
			}
		}

		if (action === "delete") {
			let deleted = await deleteUserVisited(token, markerIds);
			if (deleted.status === "error") {
				toast.error(t("userProgress.syncError"), toastOptions);
			}
		}
	};

	const isMarkerHidden = (markerId: string) => {
		return hiddenMarkers.indexOf(markerId) !== -1;
	};

	const toggleAll = () => {
		if (currentMapData.state === "hasValue" && currentMapData.contents) {
			let groups = currentMapData.contents.groups;
			let catIds: any = groups.map((g) => g.categories);
			catIds = _.flatten(catIds).map((cat: any) => cat.id);

			if (hiddenCategories.length === catIds.length) {
				setHiddenCategories([]);
			} else {
				setHiddenCategories(catIds);
			}
		}
	};

	const setActiveMarker = (markerId: string | null, resetUrl: boolean = false) => {
		_setActiveMarker(markerId);
		if (resetUrl) {
			let url = new URL(window.location.toString());
			url.searchParams.delete("marker_id");
			window.history.pushState({}, "", url.toString());
		}
	};

	return {
		maps,
		getDefaultMap,

		currentMap,
		setCurrentMap,
		currentMapData,

		toggleAll,

		hiddenCategories,
		toggleCategory,
		isCategoryHidden,
		setHiddenCategories,

		toggleGroup,

		isMarkerHidden,
		hiddenMarkers,
		toggleMarker,
		activeMarker,
		setActiveMarker,

		pushToHistory,
		goBack,
	};
};

export default useMaps;
