import L from "leaflet";
import React from "react";
import { useTranslation } from "react-i18next";
import { PopupProps, Tooltip } from "react-leaflet";
import { MapFeatureType } from "../../types/MapTypes";
import "./MarkerTooltip.scss";

interface MarkerTooltipProps extends PopupProps {
	marker: MapFeatureType;
}

const MarkerTooltip: React.FC<MarkerTooltipProps> = ({ marker, ...restProps }) => {
	const { properties } = marker;
	const { t } = useTranslation();

	const position = () => {
		if (properties.only_text === "1") {
			return "center";
		}
		switch (properties.icon_text_position) {
			case "1":
				return "left";
			case "2":
				return "top";
			case "3":
				return "right";
			case "4":
				return "bottom";
			default:
				return "right";
		}
	};

	const getOffset = () => {
		let size = parseInt(properties.icon_size);

		if (properties.only_text === "1") {
			return L.point(0, 0);
		}

		let f = () => size / 2;
		switch (position()) {
			case "left":
				return L.point(-f() / 100, 0);
			case "top":
				return L.point(0, -f() / 100);
			case "right":
				return L.point(f(), 0);
			case "bottom":
				return L.point(0, f());
		}
	};

	return (
		<Tooltip className='marker-tooltip' direction={position()} permanent interactive {...restProps}>
			<span
				style={{ color: properties.icon_text_color, fontSize: properties.icon_text_size + "px" }}
			>
				{t(`markerTexts.${properties.id}`, { defaultValue: properties.icon_text })}
			</span>
		</Tooltip>
	);
};

export default MarkerTooltip;
