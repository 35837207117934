import L, { LeafletMouseEvent, PopupEvent } from "leaflet";
import React, { useEffect, useMemo, useRef } from "react";
import { Marker as LeafletMarker, MarkerProps as LeafletMarkerProps, useMap } from "react-leaflet";
import { useBookmarks, useMaps, useMediaModal, useSiteOptions } from "../../hooks";
import { MapCategoryType, MapFeatureType } from "../../types/MapTypes";
import MarkerPopup from "../MarkerPopup/MarkerPopup";
import MarkerTooltip from "../MarkerTooltip/MarkerTooltip";
import { v4 as uuid } from "uuid";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
	ClickedMarkerState,
	MainSidebarTabIndex,
	SidePopupContentState,
	SidePopupModeState,
} from "../../store";
interface MarkerProps extends LeafletMarkerProps {
	marker: MapFeatureType;
	category: MapCategoryType;
}

const Marker: React.FC<MarkerProps> = ({ position, marker, category, ...restProps }) => {
	const map = useMap();
	const { properties } = marker;
	const { openModal } = useMediaModal();
	const {
		currentMap,
		activeMarker,
		setActiveMarker,
		currentMapData,
		setCurrentMap,
		getDefaultMap,
		pushToHistory,
		toggleMarker,
		hiddenMarkers,
	} = useMaps();

	const isSidePopupMode = useRecoilValue(SidePopupModeState);
	const { siteOptions } = useSiteOptions();
	const setPopup = useSetRecoilState(SidePopupContentState);
	const setClickedMarker = useSetRecoilState(ClickedMarkerState);
	const setActiveTab = useSetRecoilState(MainSidebarTabIndex);
	const markerRef = useRef<L.Marker>(null);
	const { addBookmark } = useBookmarks();

	useEffect(() => {
		if (activeMarker === marker.properties.id) {
			map.flyTo(position, parseInt(marker.properties.link_zoom));
			setActiveMarker(null, true);
		}
	}, [activeMarker, currentMapData, setActiveMarker, map, marker, position]);

	useEffect(() => {
		const { current: markerCurrent } = markerRef;
		if (hiddenMarkers.includes(marker.properties.id)) {
			markerCurrent && markerCurrent.setOpacity(0.5);
		} else {
			markerCurrent && markerCurrent.options.opacity !== 1 && markerCurrent.setOpacity(1);
		}
	}, [hiddenMarkers]);

	const Icon = useMemo(() => {
		const { properties } = marker;
		const { icon_image, icon_size } = properties;

		let image = icon_image ? icon_image : category.icon_image;
		let size = L.point(parseInt(icon_size), parseInt(icon_size));

		return L.icon({
			iconUrl: image,
			shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
			iconSize: size,
			shadowSize: size,
			iconAnchor: size.divideBy(2),
			popupAnchor: L.point(0, -(parseInt(icon_size) / 2)),
		});
	}, [marker.properties.icon_image, marker.properties.icon_size]);

	const handleClick = () => {
		if (properties.link && properties.direct_link === "1") {
			let link = new URL(properties.link);
			let current = new URL(window.location.toString());

			if (link.host === current.host && link.searchParams.has("map")) {
				pushToHistory({
					id: uuid(),
					mapId: currentMap,
					markerId: properties.id,
				});
				setCurrentMap(link.searchParams.get("map") || getDefaultMap());
			} else {
				window.location.href = link.toString();
			}
		}
	};

	const handleImageClick = (marker: MapFeatureType) => {
		openModal(marker.properties.image);
	};

	const handleVideoClick = (marker: MapFeatureType, src: string) => {
		openModal(src, "iframe");
	};

	const handleMouseOver = (e: LeafletMouseEvent) => {
		marker.properties.direct_link === "1" && markerRef.current?.openPopup();
	};

	const handlePopupOpen = (e: PopupEvent) => {
		setClickedMarker({ ...marker.properties, category_image: category.icon_image });
		setActiveTab(1);
		const { image, note_title, id, name, category_id } = marker.properties;
		setPopup({
			id,
			name,
			image,
			category_id,
			content: note_title,
			toggleMarker: handleMarkerToggle,
		});

		if (isSidePopupMode || siteOptions.contents?.popup_mode === "on_side") {
			e.popup.remove();
		}
	};

	const handleMarkerToggle = () => {
		toggleMarker(marker.properties.id, marker.properties.sibling_identifier);
	};

	const handleBookmarkClick = (marker: MapFeatureType) => {
		addBookmark(marker.properties.id);
	};

	return (
		<LeafletMarker
			ref={markerRef}
			position={position}
			icon={Icon}
			eventHandlers={{
				click: () => handleClick(),
				mouseover: (e) => handleMouseOver(e),
				popupopen: (e) => handlePopupOpen(e),
			}}
			{...restProps}
		>
			{marker.properties.disable_popup === "0" && (
				<MarkerPopup
					onClickImage={handleImageClick}
					onClickVideo={handleVideoClick}
					onToggleMarker={handleMarkerToggle}
					onClickBookmark={handleBookmarkClick}
					marker={marker}
				/>
			)}
			{marker.properties.icon_text && (
				<MarkerTooltip
					marker={marker}
					eventHandlers={{
						mouseover: (e) => handleMouseOver(e),
						popupopen: (e) => handlePopupOpen(e),
					}}
				/>
			)}
		</LeafletMarker>
	);
};

export default Marker;
