import React, { useRef } from "react";
import { Popup, PopupProps, useMap } from "react-leaflet";
import { MapFeatureType } from "../../types/MapTypes";
import "./MarkerPopup.scss";
import { toast, Slide } from "react-toastify";
import { useTooltips } from "../../hooks";
import parse, { Element } from "html-react-parser";
import { PopupTooltip, YoutubeIframe } from "../../components";
import { FiStar, FiX } from "react-icons/fi";
import { useTranslation } from "react-i18next";
interface MarkerPopupProps extends PopupProps {
	marker: MapFeatureType;
	onClickImage?: (marker: MapFeatureType) => void;
	onClickVideo: (marker: MapFeatureType, src: string) => void;
	onClickBookmark: (marker: MapFeatureType) => void;
	onToggleMarker: () => void;
}

const MarkerPopup: React.FC<MarkerPopupProps> = ({
	marker,
	onClickImage,
	onClickVideo,
	onClickBookmark,
	onToggleMarker,
}) => {
	const { properties } = marker;
	const { loading, error, getTooltip } = useTooltips();
	const popupRef = useRef<L.Popup>(null);
	const map = useMap();
	const { t } = useTranslation();

	const handleCopyClick = () => {
		// make url

		let url = new URL(window.location.toString());
		url.searchParams.set("marker_id", marker.properties.id);

		navigator.clipboard.writeText(url.toString());

		toast.success("Link copied", {
			position: "bottom-center",
			autoClose: 1000,
			hideProgressBar: true,
			transition: Slide,
		});
	};

	const handleToggleClick = () => {
		onToggleMarker();
	};

	const getRenderedContent = (content: string) => {
		return parse(content, {
			replace: (domNode) => {
				if (domNode instanceof Element) {
					const { tagName, attribs } = domNode;
					if (tagName === "iframe" && attribs.src.startsWith("//www.youtube.com")) {
						return (
							<YoutubeIframe
								embedUrl={attribs.src}
								onClick={() => onClickVideo(marker, attribs.src)}
							/>
						);
					}
					if (
						(tagName === "a" && attribs.href?.startsWith("tip")) ||
						attribs.href?.startsWith("#tip")
					) {
						if (error || loading) {
							return null;
						}
						let tooltipId = attribs.href?.split("-").at(-1);
						let tip = getTooltip(tooltipId as string);
						if (!tip) return null;
						return <PopupTooltip tip={tip} />;
					}
					if (
						(tagName === "img" && attribs.alt?.startsWith("tip")) ||
						attribs.alt?.startsWith("#tip")
					) {
						if (error || loading) {
							return null;
						}
						let tooltipId = attribs.alt?.split("-").at(-1);
						let tip = getTooltip(tooltipId as string);
						if (!tip) return null;
						return <PopupTooltip tip={tip} imageUrl={attribs.src} />;
					}
				}
			},
		});
	};

	const closePopup = () => {
		if (!popupRef.current || !map) return;
		map.closePopup();
	};

	return (
		<Popup ref={popupRef} autoPan={false} className='map-popup' closeButton={false}>
			<div className='marker-id-element hidden' data-marker-id={properties.id}></div>
			<div className='popup-title'>
				{t(`category.${properties.category_id}`, { defaultValue: properties.name })}
				<div className='flex space-x-1'>
					<div className={"bookmark-btn"} onClick={() => onClickBookmark(marker)}>
						<FiStar size={15} />
					</div>
					<div className='close-btn' onClick={closePopup}>
						<FiX />
					</div>
				</div>
			</div>
			{properties.image && (
				<img
					className='popup-image'
					onClick={() => onClickImage && onClickImage(marker)}
					src={properties.image}
					alt='img'
				/>
			)}
			<div className='popup-content no-styles'>
				{getRenderedContent(marker.properties.note_title)}
			</div>
			<button className='popup-btn' onClick={handleCopyClick}>
				{t("map.copyLink")}
			</button>
			<button className='popup-btn btn-red' onClick={handleToggleClick}>
				{t("map.toggleMarker")}
			</button>
		</Popup>
	);
};

export default MarkerPopup;
