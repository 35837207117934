import React, { useEffect, useMemo, useRef } from "react";
import { useMaps } from "../../hooks";
import useNotes from "../../hooks/useNotes";
import { UserPolyline } from "../../types/userTypes";
import PolylineDecorator from "../Polyline/PolylineDecorator";
import NotePopup from "./NotePopup";

const NotePolylines: React.FC = () => {
	const { userFeatures } = useNotes();
	const { currentMap } = useMaps();

	let notePolylines: UserPolyline[] = userFeatures.filter(
		(row) => row.type === "polyline" && row.map_id === currentMap
	) as UserPolyline[];

	return (
		<>
			{notePolylines.map((line) => {
				return <NotePolyline key={`note_polyline_${line.id}`} polyline={line} />;
			})}
		</>
	);
};

export default NotePolylines;

type NotePolylineProps = {
	polyline: UserPolyline;
};

const NotePolyline: React.FC<NotePolylineProps> = (props) => {
	const { polyline } = props;
	const { deleteFeature, updateFeature, activeNote } = useNotes();
	const polylineRef = useRef<L.Polyline>(null);

	const geometry = useMemo(() => JSON.parse(polyline.geometry), [polyline]);

	const handleNoteChange = (note: string) => {
		updateFeature(polyline.id, note);
	};

	const handleChangeOther = (head: any, lineGap: number) => {
		let newGeometry = { ...geometry, head: head, lineGap: lineGap };
		updateFeature(polyline.id, polyline.notes, JSON.stringify(newGeometry));
	};

	useEffect(() => {
		if (activeNote === polyline.id) {
			polylineRef.current?.openPopup();
		}
	}, [activeNote, polyline.id]);

	return (
		<>
			<PolylineDecorator
				ref={polylineRef}
				type={geometry.head}
				dashArray={geometry.lineGap}
				positions={geometry.latlngs}
			>
				<NotePopup
					feature={polyline}
					type='polyline'
					onDelete={() => deleteFeature(polyline.id)}
					onChangeNote={handleNoteChange}
					onChangeOther={handleChangeOther}
				/>
			</PolylineDecorator>
		</>
	);
};
