import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import i18Backend from "i18next-http-backend";
import { computed_url } from "./services/userApi";

i18next
	.use(i18Backend)
	.use(initReactI18next)
	.init({
		lng: "en",
		fallbackLng: "en",
		interpolation: { escapeValue: false },
		backend: { loadPath: `${computed_url}/api/{{lng}}/translation.json` },
	});

ReactDOM.render(
	<React.StrictMode>
		<RecoilRoot>
			<App />
		</RecoilRoot>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
