import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import React from "react";

type DropdownProps = {
	items: DropdownItem[];
	defaultValue?: DropdownItem;
	onChange: (item: DropdownItem) => void;
	renderButton?: (selected: DropdownItem) => React.ReactNode;
};

export type DropdownItem = {
	name: string;
	value: any;
	image?: string;
};

const Dropdown: React.FC<DropdownProps> = (props) => {
	const { items, onChange, renderButton, defaultValue } = props;

	const [selected, setSelected] = useState(defaultValue || items[0]);

	const handleChange = (item: DropdownItem) => {
		onChange(item);
		setSelected(item);
	};

	return (
		<Listbox value={selected} onChange={(item) => handleChange(item)}>
			<div className='relative'>
				{renderButton ? (
					renderButton(selected)
				) : (
					<Listbox.Button className='focus:outline-none flex items-center relative w-full cursor-pointer rounded-sm bg-black/20 py-2 pl-3 pr-10 text-left text-white shadow-md focus-visible:border-lime-500 focus-visible:ring-2 focus-visible:ring-lime-200 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
						{selected.image && (
							<span className='w-5 mr-1.5'>
								<img src={selected.image} alt={selected.image} />
							</span>
						)}
						<span className='block truncate'>{selected.name}</span>
						<span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
							<FiChevronDown className='h-5 w-5 text-gray-400' aria-hidden='true' />
						</span>
					</Listbox.Button>
				)}
				<Transition
					as={Fragment}
					leave='transition ease-in duration-100'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<Listbox.Options className='focus:outline-none absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-theme-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm z-[5000]'>
						{items.map((row, rowIdx) => (
							<Listbox.Option
								key={rowIdx}
								className={({ active }) =>
									`relative cursor-pointer select-none py-1 px-2 ${
										active ? "bg-white/10 text-white" : "text-white"
									}`
								}
								value={row}
							>
								{({ selected }) => (
									<div className='flex items-center'>
										{row.image && (
											<span className='w-5 mr-1.5'>
												<img src={row.image} alt={row.image} />
											</span>
										)}
										<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
											{row.name}
										</span>
									</div>
								)}
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Transition>
			</div>
		</Listbox>
	);
};

export default Dropdown;
